import React, { useState } from 'react';
import AutopsyReport1 from './AutopsyReport1.png';
import AutopsyReport2 from './AutopsyReport2.png';

function DinnerComponent() {
    const [brittanySeat, setBrittanySeat] = useState('');
    const [dahliaSeat, setDahliaSeat] = useState('');
    const [edgarSeat, setEdgarSeat] = useState('');
    const [victorSeat, setVictorSeat] = useState('');
    const [leoSeat, setLeoSeat] = useState('');
    const [harperSeat, setHarperSeat] = useState('');
    const [ceciliaSeat, setCeciliaSeat] = useState('');
    const [marinaSeat, setMarinaSeat] = useState('');
    const [solution, setSolution] = useState(false);
    const [error, setError] = useState(null);

    const handleBrittanyChange = (event) => {
        setBrittanySeat(event.target.value);
    }

    const handleEdgarChange = (event) => {
        setEdgarSeat(event.target.value);
    }

    const handleDahliaChange = (event) => {
        setDahliaSeat(event.target.value);
    }

    const handleVictorChange = (event) => {
        setVictorSeat(event.target.value);
    }

    const handleLeoChange = (event) => {
        setLeoSeat(event.target.value);
    }

    const handleHarperChange = (event) => {
        setHarperSeat(event.target.value);
    }

    const handleMarinaChange = (event) => {
        setMarinaSeat(event.target.value);
    }

    const handleCeciliaChange = (event) => {
        setCeciliaSeat(event.target.value);
    }

    const trySolution = (event) => {
        event.preventDefault();
        
        if (brittanySeat === "6"
            && edgarSeat === "3"
            && dahliaSeat === "5"
            && victorSeat === "4"
            && leoSeat === "1"
            && harperSeat === "8"
            && ceciliaSeat === "2"
            && marinaSeat === "7"
        ) {
            setError(null);
            setSolution(true);
        } else {
            setError('Incorrect - try again');
            setSolution(false);
        }
    }

    return (
        <div className="case-data">
        <form onSubmit={trySolution}>
            <div className="case-form">
                <p>Seat Assignments</p>
                <div>
                    <label>
                        Victor
                    </label>
                    <input type="text" value={victorSeat} onChange={handleVictorChange} className="num-input"/>
                </div>
                <div>
                    <label>
                        Brittany
                    </label>
                    <input type="text" value={brittanySeat} onChange={handleBrittanyChange} className="num-input"/>
                </div>
                <div>
                    <label>
                        Edgar
                    </label>
                    <input type="text" value={edgarSeat} onChange={handleEdgarChange} className="num-input"/>
                </div>
                <div>
                    <label>
                        Cecilia
                    </label>
                    <input type="text" value={ceciliaSeat} onChange={handleCeciliaChange} className="num-input"/>
                </div>
                <div>
                    <label>
                        Harper
                    </label>
                    <input type="text" value={harperSeat} onChange={handleHarperChange} className="num-input"/>
                </div>
                <div>
                    <label>
                        Leo
                    </label>
                    <input type="text" value={leoSeat} onChange={handleLeoChange} className="num-input"/>
                </div>
                <div>
                    <label>
                        Marina
                    </label>
                    <input type="text" value={marinaSeat} onChange={handleMarinaChange} className="num-input"/>
                </div>
                <div>
                    <label>
                        Dahlia
                    </label>
                    <input type="text" value={dahliaSeat} onChange={handleDahliaChange} className="num-input"/>
                </div>
            </div>
            <button type="submit" className="custom-button">Enter</button>
        </form>

        {error && <p>{error}</p>}
        {/* {solution && <iframe src={LakesideBodyShop} type="application/pdf" />} */}
        {solution && <div>
            <p>
                Congratulations! You have completed this puzzle.
                It's time to deliberate on who is guilty.
            </p>
            <img src={AutopsyReport1} />
            <img src={AutopsyReport2} />
        </div>}
        </div>
    );
}

export default DinnerComponent;
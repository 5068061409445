import React, { useState } from 'react';
import OriginalWill1 from './OriginalWill1.png';
import OriginalWill2 from './OriginalWill2.png';

function CryptogramComponent() {
    const [password, setPassword] = useState('');
    const [caseData, setCaseData] = useState(false);
    const [error, setError] = useState(null);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const tryPassword = (event) => {
        event.preventDefault();

        const answer = `Victor, you failed to protect us, but we know Harper 
        is in the will. You'll pay for turning your back on family. Watch your back—we won't let 
        you destroy everything without consequences.`;
        
        if (password.toLowerCase().replace(/\W/g, '') === answer.toLowerCase().replace(/\W/g, '')) {
            setError(null);
            setCaseData(true);
        } else {
            setError('Message incorrect');
            setCaseData(false);
        }
    }

    return (
        <div className="case-data">
        <form onSubmit={tryPassword}>
            <div className="case-form">
            <label className="block-label">
                Decoded message:
            </label>
            <textarea rows="4" cols="50" onChange={handlePasswordChange}/>
            </div>
            <button type="submit" className="custom-button">Enter</button>
        </form>

        {error && <p>{error}</p>}
        {caseData &&
        <div>
            <p>Congratulations! You have completed this puzzle.</p>
            <img src={OriginalWill1} />
            <img src={OriginalWill2} />
        </div>
        }
        </div>
    );
}

export default CryptogramComponent;
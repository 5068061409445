import React, { useState } from 'react';

function NewspaperComponent() {
    const [password, setPassword] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const tryPassword = (event) => {
        event.preventDefault();
        
        if (password.toLowerCase() === "pay 10,000 or kids get killed") {
        setError(null);
        setSuccess(true);
        } else {
        setError('Incorrect - try again');
        setSuccess(false);
        }
    }

    return (
        <div className="case-data">
        <form onSubmit={tryPassword}>
            <div className="case-form">
            <label>
                Message:
            </label>
            <input type="text" value={password} onChange={handlePasswordChange}/>
            </div>
            <button type="submit" className="custom-button">Enter</button>
        </form>

        {error && <p>{error}</p>}
        {success &&
        <p>Congrats! You've completed the puzzle and found the correct message.
            Please wait for further instructions tomorrow.</p>}
        </div>
    );
}

export default NewspaperComponent;
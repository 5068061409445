import React, { useState } from 'react';
import BodyShop1 from './LakesideBodyShop1.png';
import BodyShop2 from './LakesideBodyShop2.png';

function BacComponent() {
    const [brittanyBac, setBrittanyBac] = useState('');
    const [dahliaBac, setDahliaBac] = useState('');
    const [edgarBac, setEdgarBac] = useState('');
    const [name, setName] = useState('');
    const [solution, setSolution] = useState(false);
    const [error, setError] = useState(null);

    const handleBrittanyChange = (event) => {
        setBrittanyBac(event.target.value);
    }

    const handleEdgarChange = (event) => {
        setEdgarBac(event.target.value);
    }

    const handleDahliaChange = (event) => {
        setDahliaBac(event.target.value);
    }

    const handleNameChange = (event) => {
        setName(event.target.value);
    }

    const trySolution = (event) => {
        event.preventDefault();
        
        // Brittany - 140 lbs, 1.5 hours since first drink, 2 liquor
        // Edgar - 180 lbs, 1 hour since first drink, 2 beer (as pint)
        // Dahlia - 120 lbs, 2 hours since first drink, 2 wine
        if (brittanyBac === "05"
            && edgarBac === "05"
            && dahliaBac === "05"
            && (name.toLowerCase() === "edgar") || name.toLowerCase() === "edgar thomas") {
            setError(null);
            setSolution(true);
        } else {
            setError('Incorrect - try again');
            setSolution(false);
        }
    }

    return (
        <div className="case-data">
        <form onSubmit={trySolution}>
            <div className="case-form">
                <p>Round to 2 decimal places</p>
                <p>Calculator: https://www.calculator.net/bac-calculator.html</p>
            <div>
                <label>
                    Dahlia's BAC: 0.
                </label>
                <input type="text" value={dahliaBac} onChange={handleDahliaChange} className="num-input"/>
                <label> %</label>
            </div>
            <div>
                <label>
                    Brittany's BAC: 0.
                </label>
                <input type="text" value={brittanyBac} onChange={handleBrittanyChange} className="num-input"/>
                <label> %</label>
            </div>
            <div>
                <label>
                    Man's BAC: 0.
                </label>
                <input type="text" value={edgarBac} onChange={handleEdgarChange} className="num-input"/>
                <label> %</label>
            </div>
            <div>
                <label>
                    Who was the man they were with?
                </label>
                <input type="text" value={name} onChange={handleNameChange} />
            </div>
            </div>
            <button type="submit" className="custom-button">Enter</button>
        </form>

        {error && <p>{error}</p>}
        {/* {solution && <iframe src={LakesideBodyShop} type="application/pdf" />} */}
        {solution && <div>
            <p>Congratulations! You have completed this puzzle.</p>
            <img src={BodyShop1} />
            <img src={BodyShop2} />
        </div>}
        </div>
    );
}

export default BacComponent;
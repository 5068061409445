import './App.css';
import React, { useState } from 'react';
import LedgerComponent from './LedgerComponent';
import BacComponent from './BacComponent';
import CryptogramComponent from './CryptogramComponent';
import LogicComponent from './LogicComponent';
import ScheduleComponent from './ScheduleComponent';
import DinnerComponent from './DinnerComponent';
import NewspaperComponent from './NewspaperComponent';

const caseNumberMap = {
  newspaper: "90952",
  harperLedger: "73821",
  edgarSchedule: "45902",
  dahliaLogic: "61357",
  barReceipts: "98765",
  cryptogram: "24680",
  dinner: "10932",
}

function CaseDetails({ caseNumber }) {
  switch (caseNumber) {
    case caseNumberMap.harperLedger:
      return (<LedgerComponent />);
    case caseNumberMap.edgarSchedule:
      return (<ScheduleComponent />);
    case caseNumberMap.dahliaLogic:
      return (<LogicComponent />);
    case caseNumberMap.barReceipts:
      return (<BacComponent />);
    case caseNumberMap.cryptogram:
      return (<CryptogramComponent />);
    case caseNumberMap.dinner:
      return (<DinnerComponent />);
    case caseNumberMap.newspaper:
      return(<NewspaperComponent />)
    default:
      return (<p>Case Unknown</p>);
  }
}

function CaseNumberForm() {
  const [caseNumber, setCaseNumber] = useState('');
  const [caseData, setCaseData] = useState(false);
  const [error, setError] = useState(null);

  const handleCaseNumberChange = (event) => {
    setCaseNumber(event.target.value);
    setCaseData(false);
  }

  const searchCase = (event) => {
    event.preventDefault();
    
    if (Object.values(caseNumberMap).includes(caseNumber)) {
      setError(null);
      setCaseData(true);
    } else {
      setError('Case not found');
      setCaseData(false);
    }
  }

  return (
    <div className="container">
      <div className="content">
        <form onSubmit={searchCase}>
          <div className="case-form">
            <label>
              Case Number:
            </label>
            <input type="number" pattern="[0-9]*" inputMode="numeric" value={caseNumber} onChange={handleCaseNumberChange}/>
          </div>
          <button type="submit" className="custom-button">Search</button>
        </form>

        {error && <p>{error}</p>}
        {caseData && <CaseDetails caseNumber={caseNumber} />}
      </div>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Broken Bow Detective Unit Database
        </p>
      </header>
      <CaseNumberForm />
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import EmailImage from './EdgarToDahlia.png';

function ScheduleComponent() {
    const [password, setPassword] = useState('');
    const [caseData, setCaseData] = useState(false);
    const [error, setError] = useState(null);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const tryPassword = (event) => {
        event.preventDefault();
        
        if (password.toLowerCase() === "dahlia") {
        setError(null);
        setCaseData(true);
        } else {
        setError('Incorrect password');
        setCaseData(false);
        }
    }

    return (
        <div className="case-data">
        <form onSubmit={tryPassword}>
            <div className="case-form">
            <label>
                Password:
            </label>
            <input type="text" value={password} onChange={handlePasswordChange}/>
            </div>
            <button type="submit" className="custom-button">Enter</button>
        </form>

        {error && <p>{error}</p>}
        {caseData &&
        <div>
            <p>Congratulations! You have completed this puzzle.</p>
            <img src={EmailImage} />
        </div>
        }
        </div>
    );
}

export default ScheduleComponent;
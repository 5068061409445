import HarperRecording from './HarperRecording.mp3';
import React, { useState } from 'react';

function LedgerComponent() {
    const [password, setPassword] = useState('');
    const [recording, setRecording] = useState(false);
    const [error, setError] = useState(null);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const tryPassword = (event) => {
        event.preventDefault();
        
        if (password === "25180") {
        setError(null);
        setRecording(true);
        } else {
        setError('Incorrect password');
        setRecording(false);
        }
    }

    return (
        <div className="case-data">
        <form onSubmit={tryPassword}>
            <div className="case-form">
            <label>
                Password:
            </label>
            <input type="text" value={password} onChange={handlePasswordChange}/>
            <label className="hint"> Hint: amount embezzled</label>
            </div>
            <button type="submit" className="custom-button">Enter</button>
        </form>

        {error && <p>{error}</p>}
        {recording &&
        <div>
        <audio controls title="Recording">
            <source src={HarperRecording} type="audio/mpeg" />
            Your browser does not support the audio element.
        </audio>
        <p>Congratulations! You have completed this puzzle.</p>
        </div>
        }
        </div>
    );
}

export default LedgerComponent;
import React, { useState } from 'react';
import DahliaEmail from './DahliaEmail.png';

function LogicComponent() {
    const defaultSelections = [
        ['bribery', 'Northstar', 'a cover up'],
        ['bribery', 'Northstar', 'a cover up'],
        ['bribery', 'Northstar', 'a cover up'],
        ['bribery', 'Northstar', 'a cover up'],
    ];
    const [userSelections, setUserSelections] = useState(defaultSelections);
    const [caseData, setCaseData] = useState(false);
    const [error, setError] = useState(null);

    const categories = ['Suspects', 'Actions', 'Locations', 'Methods'];
    const characters = ['Leo', 'Harper', 'Marina', 'Cecilia'];
    const actions = ['a cover up', 'illegal testing', 'distribution', 'selling drugs'];
    const locations = ['Northstar', 'Art Gallery', 'New Horizons', 'Astor Enterprises'];
    const methods = ['bribery', 'intimidation', 'fraud', 'kidnapping'];

    const correctAnswers = [
        ['intimidation', 'New Horizons', 'selling drugs'],
        ['bribery', 'Astor Enterprises', 'a cover up'],
        ['fraud', 'Art Gallery', 'distribution'],
        ['kidnapping', 'Northstar', 'illegal testing'],
    ];

    const checkSolution = (event) => {
        event.preventDefault();
        for (let i = 0; i < correctAnswers.length; i++) {
            for (let j = 0; j < correctAnswers[0].length; j++) {
                if (correctAnswers[i][j] !== userSelections[i][j]) {
                    setError('Incorrect - try again');
                    setCaseData(false);
                    return;
                }
            }
        }
        setError(null);
        setCaseData(true);
    }

    const handleSelectionChange = (rowIndex, columnIndex, value) => {
        const newUserSelections = [...userSelections];
        newUserSelections[rowIndex][columnIndex] = value;
        setUserSelections(newUserSelections);
    }

    return (
        <div className="case-data">
            <div className="case-form">
            <div className="mobile-content">
                Please view landscape mode on mobile, or on a tablet or computer.
            </div>
            <table>
                <thead>
                    <tr>
                        {categories.map((category, index) => (
                        <th key={index}>{category}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {characters.map((character, index) => (
                        <tr key={index}>
                        <td>{character}</td>
                        <td>
                            used
                            <select onChange={(e) => handleSelectionChange(index, 0, e.target.value)}>
                            {methods.map((method, index) => (
                                <option key={index} value={method}>{method}</option>
                            ))}
                            </select>
                        </td>
                        <td>
                            at
                            <select onChange={(e) => handleSelectionChange(index, 1, e.target.value)}>
                            {locations.map((location, index) => (
                                <option key={index} value={location}>{location}</option>
                            ))}
                            </select>
                        </td>
                        <td>
                            for
                            <select onChange={(e) => handleSelectionChange(index, 2, e.target.value)}>
                            {actions.map((action, index) => (
                                <option key={index} value={action}>{action}</option>
                            ))}
                            </select>
                        </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button type="submit" className="custom-button" onClick={checkSolution}>Submit</button>
            </div>
            {error && <p>{error}</p>}
            {caseData && <div>
                <p>Congratulations! You have completed this puzzle.</p><img src={DahliaEmail} />
            </div>}
        </div>
    );
}

export default LogicComponent;